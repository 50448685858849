import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import title_image from '../images/titles/blog.jpg'
import blog_business_plan from '../images/blog/blog_business_plan.jpg'
import blog_insurance from '../images/blog/blog_insurance.jpg'
import blog_machine_learning from '../images/blog/blog_machine_learning.jpg'
import blog_performance_measurement from '../images/blog/blog_performance_measurement.jpg'
class BlogListTemplate extends React.Component{
  render(){
    return(
      <Layout>
      <SEO title="Blog" />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">Our Blog</h1>
            <h6 className="subtitle">Whether you have a business or technology need, you’ve come 
            to the right place to find information you can use.</h6>
        </div>
      </section>
      
      <section id="p_blog" className="section static-page page-content padding-bottom-0">
        <div className="container">
            <div className="columns margin-top-0">
                <div className="column is-12 content-area">
                    <h3>Are you in need of expert advice or practical information that 
                      can solve some of your concerns about businesses and technology?</h3>
                    <p>At Nuerex, we’re continually updating our list of expert opinions to 
                      give a greater insight into how we do our work.</p>
                    <p>If you have any questions related to business and technology, our insight page holds valuable 
                      facts. Whether you’re searching for news, articles, or resources, we’re always glad to help.</p>
                    <p>We’re passionate about sharing knowledge; we regularly discuss our expertise, personal 
                      experiences, and thoughts on how consultants and clients can work together.</p>
                </div>
            </div>
        </div>
      </section>
      <section id="p_blog" className="section static-page blog-list white">
          <div className="container">
          <div className="page-title-area blue">
                  <h2 className="title">OUR LATEST BLOGS</h2>
              </div>
              <div className="columns is-multiline">
                  <div className="single column is-4">
                      <Link to="/blog/the-importance-of-a-business-transformation-plan-in-implementing-real-change-to-any-business-or-organization" className="inner">
                        <img src={blog_business_plan} />
                        <p className="category"><span className="line"></span>BUSINESS</p>
                        <h2 className="title">The importance of a business transformation plan in implementing real change to any business or organization</h2>
                      </Link>
                  </div>

                  <div className="single column is-4">
                      <Link to="/blog/the-importance-of-performance-metrics-measurable-data-is-manageable-data" className="inner">
                        <img src={blog_performance_measurement} />
                        <p className="category"><span className="line"></span>TECHNOLOGY</p>
                        <h2 className="title">The importance of performance metrics. Measurable data is manageable data</h2>
                      </Link>
                  </div>

                  <div className="single column is-4">
                      <Link to="/blog/misconceptions-and-misinformation-about-machine-learning-consultants-may-affect-your-business" className="inner">
                        <img src={blog_machine_learning} />
                        <p className="category"><span className="line"></span>TECHNOLOGY</p>
                        <h2 className="title">Misconceptions and Misinformation about Machine Learning consultants may affect your business</h2>
                      </Link>
                  </div>

                  <div className="single column is-4">
                      <Link to="/blog/using-rpa-to-improve-workflow-in-the-insurance-industry" className="inner">
                        <img src={blog_insurance} />
                        <p className="category"><span className="line"></span>TECHNOLOGY</p>
                        <h2 className="title">Using RPA to improve workflow in the insurance industry</h2>
                      </Link>
                  </div>
              </div>

             {/* <div className="navigation">
                  <div className="inner">
                      <span className="current">1</span>
                      <Link to="/">2</Link>
                      <Link to="/">3</Link>
                      <Link to="/" className="text">Next</Link>
                  </div>
              </div>
            */}
          </div>
      </section>
      </Layout>
    )
  }
}

export default BlogListTemplate
